import * as S from './styles';
import { useState, useRef } from 'react';
import { CategoryFilter } from './CategoryFilter/CategoryFilter';
import { ColorMode } from './ColorMode/ColorMode';
import { PersonalData } from './PersonalData/PersonalData';
import { ProfilePopover } from './Popover/ProfilePopover/ProfilePopover';
import { FilterPopover } from './Popover/FilterPopover/FilterPopover';

export const Menu = ({loggedIn}) => {
    const wrapperRef = useRef(null);
    const [isProfilePopoverMounted, setIsProfilePopoverMounted] = useState(false);
    const [showProfilePopover, setShowProfilePopover] = useState(false);

    const switchProfilePopoverVisibility = () => {
        setIsProfilePopoverMounted(!isProfilePopoverMounted);
        if (!showProfilePopover) {
            setShowProfilePopover(true);
            if (showFilterPopover) switchFilterPopoverVisibility();
        }
    }

    const [isFilterPopoverMounted, setIsFilterPopoverMounted] = useState(false);
    const [showFilterPopover, setShowFilterPopover] = useState(false);

    const switchFilterPopoverVisibility = () => {
        setIsFilterPopoverMounted(!isFilterPopoverMounted);
        if (!showFilterPopover) {
            setShowFilterPopover(true);
            if (showProfilePopover) switchProfilePopoverVisibility();
        } 
    }

    return (
        <S.Menu ref={wrapperRef}>
            <ColorMode />
            {loggedIn && <CategoryFilter onClick={switchFilterPopoverVisibility} />}
            {loggedIn && <PersonalData onClick={switchProfilePopoverVisibility} />}
            {showProfilePopover && (<ProfilePopover setShowPopover={setShowProfilePopover} showPopover={showProfilePopover} isPopoverMounted={isProfilePopoverMounted} switchVisibility={switchProfilePopoverVisibility} menuRef={wrapperRef} />)}
            {showFilterPopover && (<FilterPopover setShowPopover={setShowFilterPopover} showPopover={showFilterPopover} isPopoverMounted={isFilterPopoverMounted} switchVisibility={switchFilterPopoverVisibility} menuRef={wrapperRef} />)}
        </S.Menu>
    )
}
