import React, {useEffect, useState} from 'react';
import { useAuth } from '../../AuthorizationProvider';
import { Header } from '../MainPage/Header/Header.js';
import { HomeBackground } from '../HomeBackground.js';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import * as S1 from '../styles';
import * as S2 from './styles';
import Theme from '../../Theme.js';

const S = {
    ...S1,
    ...S2
};
  
const errorMessage = "Nieprawidłowa nazwa użytkownika lub hasło"

export const LoginPage = () => {

  const { onLoginClick, onLoginCallback, onLoginDemo, getUniversities, onUniversityChange } = useAuth();

  const [usosLoginDisabled, setUsosLoginDisabled] = useState(true)
  const [animationFinished, setAnimationFinished] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseLeave = (event) => {
    setAnimationFinished(true)
  };

  const handleLoginReal = async (event) => {
    await onLoginClick(event);
  };

  const handleUniversityChange = (event) => {
    onUniversityChange(event);
    setUsosLoginDisabled(false);
  }

  const [universities, setUniversities] = useState([{"id":"0", "name":"Wczytywanie wspieranych uczelni"}]);

  useEffect(() => {
    setIsLoading(false);
    const searchParams = new URLSearchParams(window.location.search);

    // Check if there is verifier (callback from USOS)
    if (searchParams.has("oauth_verifier")) {
      setIsLoading(true)
      onLoginCallback(searchParams.get("oauth_verifier"));
    }

    getUniversities().then(response => {
      setUniversities(response)
      document.querySelector('select').options[0].selected = true
    });
  }, []);

  return (
  <>
    <Header loggedIn={false}/>
    <HomeBackground/>
    <S.HomePage>
      {isLoading && <S.LoadingSpinner animation="border" role='status' variant='success'/>}
      <S.HomeForm>
        <S.Title>Witaj w Student Stats</S.Title>
        <S.Selection defaultValue="" onChange={handleUniversityChange}>
          <S.Option value="" disabled hidden>Wybierz swoją uczelnię</S.Option>
          { universities.map(university =>
              <S.Option key={university.id} value={university.id}>
                {university.name}
              </S.Option>)}
        </S.Selection>
        <S.LoginPageButton disabled={usosLoginDisabled} type="button" onClick={handleLoginReal}>Zaloguj się przez USOS</S.LoginPageButton>
        <S.LoginPageButton type="button" onClick={onLoginDemo}>Zaloguj się do demo</S.LoginPageButton>
      </S.HomeForm>
      <S.InfoBox onMouseLeave={handleMouseLeave} animationFinished={animationFinished}>
          <S.InfoMessage>
            StudentStats to platforma stworzona w celu umożliwienia studentom PWr przeglądania swoich statystyk w przystępnej i atrakcyjnej formie. Zaloguj się swoimi danymi do USOSa i sprawdź jak Ci idzie na studiach.
          </S.InfoMessage>
    </S.InfoBox>
    </S.HomePage>
  </>
);
};