import React, { useState } from 'react';
import * as S1 from '../styles';
import * as S2 from './styles';
import { Header } from '../MainPage/Header/Header.js';
import { HomeBackground } from '../HomeBackground.js';
import questions from './trivia.json';

const S = {
    ...S1,
    ...S2
};

const motivationMessages = { happy: ['Super!', 'Świetnie!', 'Wspaniale!', 'Brawo!', 'Dobrze!', 'Tak!'], sad: ['Nie.', 'Źle!!!', 'Błąd...', 'Nie!', 'Oj źle!', 'OoF!', 'No nie!'] }
const nextMessages = ['Kolejne?', 'Jeszcze raz?', 'Następne?', 'No weź jeszcze raz...', 'Jeszcze jedno?', 'Kolejne pytanie?', 'Następne pytanie?']

// TODO: swap to endpoint in future
const previousQuestions = [];
function getRandomQuestion() {
    let question = questions[Math.floor(Math.random() * questions.length)];
    while (previousQuestions.includes(question)) {
        question = questions[Math.floor(Math.random() * questions.length)];
    }
    previousQuestions.push(question);
    if (previousQuestions.length > questions.length - 1) previousQuestions.shift();
    const answers = question.answers;
    const correctAnswer = question.correct;
    return [question.question, answers, correctAnswer];
}

function getRandomMessage(wasCorrect, streak) {
    return `
        ${wasCorrect ?
            getRandomHappyMessage() :
            getRandomSadMessage()
        } 
        ${streak > 1 ? `To już ${streak} pod rząd!` : ''} 
        ${getRandomNextMessage()}
    `
} 

function getRandomHappyMessage() {
    return motivationMessages.happy[Math.floor(Math.random() * motivationMessages.happy.length)];
}

function getRandomSadMessage() {
    return motivationMessages.sad[Math.floor(Math.random() * motivationMessages.sad.length)];
}

function getRandomNextMessage() {
    return nextMessages[Math.floor(Math.random() * nextMessages.length)];
}

const TriviaButtons = ({ answers, correctAnswer, setQuestion }) => {
    const [userAnswer, setUserAnswer] = useState(null);
    const [streak, setStreak] = useState(0);
    const [messageAfterAnswer, setMessageAfterAnswer] = useState('');

    const checkAnswer = (user) => {
        if (userAnswer !== null) return;
        setUserAnswer(user);
        const isCorrect = user === correctAnswer;
        setStreak((oldStreak) => isCorrect ? oldStreak + 1 : 0);
        setMessageAfterAnswer(getRandomMessage(isCorrect, isCorrect ? streak + 1 : 0));
    }

    const refreshButtons = () => {
        if (userAnswer === null) return;
        setUserAnswer(null);
        setMessageAfterAnswer('');
        setQuestion(getRandomQuestion());
    }

    const answerButtons = answers.map((answer, index) => {
        const isNoUserAnswer = userAnswer === null;
        const isCorrectAnswer = correctAnswer === index;
        const isSelectedAndIncorrect = userAnswer === index && userAnswer !== correctAnswer;
        const outlineClass = isSelectedAndIncorrect ? '' : 'outline';
        
        const className = isNoUserAnswer ? 'outline' : (isCorrectAnswer ? 'highlight' : `faded ${outlineClass}`);
        const isFontSmall = answer.length > 10;
        
        return <S.Button
            type="button"
            className={className}
            isFontSmall={isFontSmall}
            onClick={() => checkAnswer(index)}
            key={index}>
            {answer}
        </S.Button>
    })

    const actionButtonText =
        userAnswer === null ? `Wybierz dobrze...` : messageAfterAnswer;

    const actionButtonClass = userAnswer === null ? 'faded' : 'outline';
    return (
        <S.TriviaButtons>
            {answerButtons}
            <S.Button
                type="button"
                className={actionButtonClass}
                onClick={refreshButtons}>
                {actionButtonText}
            </S.Button>
        </S.TriviaButtons>
    )
}

const Trivia = () => {
    const [question, setQuestion] = useState(getRandomQuestion());
    const [questionText, answers, correctAnswer] = question;

    return (
        <S.Trivia>
            <S.Question>{questionText}</S.Question>
            <TriviaButtons answers={answers} correctAnswer={correctAnswer} setQuestion={setQuestion}></TriviaButtons>
        </S.Trivia>
    )
}

export const Page404 = () => {
    return (
    <>
        <Header loggedIn={false}/>
        <HomeBackground/>
        <S.HomePageFlexible>
            <S.Info404>
                <S.LargeTitle>404</S.LargeTitle>
                <S.Paragraph>Oops! Nie znaleźliśmy tej strony, ale za to mamy fajny quiz.</S.Paragraph>
                <S.Button as="a" href="/">Wróć do logowania</S.Button>
            </S.Info404>
            <S.HomeForm>
                <S.TriviaTitle>PWR QUIZ</S.TriviaTitle>
                <S.TriviaSubtitle>Jak dobrze znasz swoją uczelnię?</S.TriviaSubtitle>
                <Trivia />
            </S.HomeForm>
        </S.HomePageFlexible>
    </>
    );
};