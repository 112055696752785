import styled from "styled-components";
import { Button } from '../styles';
import { lightTheme } from "../../Theme";
import { Spinner } from "react-bootstrap";

export const LoginPageButton = styled(Button)`
    width: 70%;

    &:hover {
      background-color: #55a265;
      transition: background-color 0.3s ease;
    }

    &:active {
      background-color: #3C6B43;
    }

    &:disabled {
        background-color: hsla(120, 20%, 35%, 0.85);
        pointer-events: none;
    }
` 

export const Message = styled.p`
  color: red;
  margin-bottom: 10px;
`

export const InfoBox = styled.div`
  border-radius: 10px;
  height: 96px;
  position: relative;
  width: 30px;
  background: ${({ theme }) => theme.colorMode.loginForm.backgroundColor};
  justify-content: center;
  align-items: center;
  margin: 10px;
  min-width: min(60vh, 60vw);
  max-width: min(500px, 90vw);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  transition: background .5s ease, color .5s ease;

  @media screen and (max-width: 1024px) {
    height: 144px;
    width: 60vh;
  }
`

export const InfoMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  width: 100%;
  color: ${({ theme }) => theme.colorMode.fontColor};
  line-height: 1.25;
  padding: 10px;
`

export const Input = styled.input`
  border: none;
  border-bottom: 2px solid #4d4d4d;
  color: ${({ theme }) => theme.colorMode.fontColor};
  padding: 0px 0.5vh;
  margin-bottom: 2.5vh;
  width: 100%;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  background: transparent;
  background-blend-mode: multiply;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
`;

export const Selection = styled.select`
    width: 80%;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 20px;
`

export const Option = styled.option`
`

export const LoadingSpinner = styled(Spinner)`
  width: 3rem;
  height: 3rem;
`;
