import React, { useRef } from 'react';
import * as S1 from "../styles";
import * as S2 from "./styles";
import { useEffect, useContext } from 'react';
import { useAuth } from '../../../../../../AuthorizationProvider';
import { Popover } from '../Popover';
import { FiltersContext } from '../../../../FiltersContext'; 

const S = {
    ...S1,
    ...S2
};

// TODO: adjust for real data
const tranlationEnToPl = {
    small: 'mały',
    medium: 'średni',
    big: 'duży',
    PAYMENTS: 'płatności',
    GPA: 'średnia ocen',
    PROGRESS_OF_SEMESTER: 'postęp semestru',
    PROGRESS_OF_STUDIES: 'postęp studiów',
    SCHOLARSHIPS: 'stypendia',
    UNIVERSITY_STAFF: 'pracownicy uczelni',
    LIBRARY: 'biblioteka',
}

const CheckboxLabel = ({ filterKey, isChecked, updateTilesFilter }) => {
    const cleanText = tranlationEnToPl.hasOwnProperty(filterKey) ? tranlationEnToPl[filterKey] : filterKey;

    return (
        <S.CheckboxLabel>
            <S.Checkbox type='checkbox' id={ filterKey } onChange={updateTilesFilter} defaultChecked={ isChecked }/>
            <S.Label htmlFor={ filterKey }>{ cleanText }</S.Label>
        </S.CheckboxLabel>
    );
}

const generateCheckboxes = (array, filterType, tilesFilter, setTilesFilter) => {

    const updateTilesFilter = (event) => {
        const filterKey = event.target.id;
        const index = tilesFilter[filterType].indexOf(filterKey);
        if(index === -1) tilesFilter[filterType].push(filterKey);
        else tilesFilter[filterType].splice(index, 1);

        const tilesFilterCopy = JSON.parse(JSON.stringify(tilesFilter));
        
        setTilesFilter(tilesFilterCopy);
    }

    return array.map(keyFilter => {
        const isChecked = tilesFilter[filterType].includes(keyFilter);
        return <CheckboxLabel key={ keyFilter } filterKey={ keyFilter } isChecked={ isChecked } updateTilesFilter={updateTilesFilter}/>
    })
}

export const FilterPopover = ({ setShowPopover, isPopoverMounted, switchVisibility, menuRef }) => {
   
    const { tilesFilter, setTilesFilter } = useContext(FiltersContext);

    // sizes checkboxes
    const sizesCheckboxes = useRef([]);
    const sizes = ['small', 'medium', 'big'];

    sizesCheckboxes.current = generateCheckboxes(sizes, 'sizes', tilesFilter, setTilesFilter);

    // categories checkboxes
    const categoriesCheckboxes = useRef([]);
    const categories = new Set();

    const tiles = JSON.parse(localStorage.getItem('tiles'));
    tiles.forEach(tile => {
        if(tile !== null) categories.add(tile.category);
    });
    
    categoriesCheckboxes.current = generateCheckboxes([...categories].filter(c => c !== 'BRANDING'), 'categories', tilesFilter, setTilesFilter);

    return (
        <Popover setShowPopover={setShowPopover} isPopoverMounted={isPopoverMounted} switchVisibility={switchVisibility} menuRef={menuRef}>
            <S.FilterTitleLarge>Filtrowanie kafelków</S.FilterTitleLarge>
            <S.FilterType>
                <S.FilterTitle>Rozmiar</S.FilterTitle>
                <S.FilterSizes>
                    { sizesCheckboxes.current }
                </S.FilterSizes>
            </S.FilterType>
            <S.FilterType>
                <S.FilterTitle>Kategoria</S.FilterTitle>
                { categoriesCheckboxes.current }
            </S.FilterType>

        </Popover>
    );
}