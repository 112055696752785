import React from 'react';
import * as S1 from "../styles";
import * as S2 from "./styles";
import { Popover } from '../Popover';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../../../../AuthorizationProvider';

const S = {
    ...S1,
    ...S2
};

const LogoutButton = () => {
    const { onLogout } = useAuth();
    return (
        <S.LogoutButton onClick={onLogout}>
            <S.LogoutIcon viewBox="0 -960 960 960">
                <path 
                    d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621-612l43-43 176 176-174 174Z"
                />
            </S.LogoutIcon>
        </S.LogoutButton>
    )
}

const DataRow = ({title1, value1, title2, value2}) => {
    return (
    <S.ProfileDataRow>
        {title1 && value1 &&
        <S.ProfileItem style={{marginRight: '1rem'}}>
            <S.TextLineTitle>{ title1 }</S.TextLineTitle>
            <S.TextLineValue>{ value1 }</S.TextLineValue>
        </S.ProfileItem>
        }
        {title2 && value2 &&
        <S.ProfileItem>
            <S.TextLineTitle>{ title2 }</S.TextLineTitle>
            <S.TextLineValue>{ value2 }</S.TextLineValue>
        </S.ProfileItem>
        }
    </S.ProfileDataRow>
    );
}

const mockUserData = {
    "firstName": "Imię",
    "lastName": "Nazwisko",
    "currentFaculty": "Wydział",
    "currentMajor": "Kierunek",
    "semester": 1,
    "currentStageOfStudies": "I stopnia",
    "studentStatus": "ACTIVE_STUDENT",
    "phdStudentStatus": "NOT_A_STUDENT",
    "indexNumber": 123456,
    "studiesType": "stacjonarne",
    "studies": []
};

export const ProfilePopover = ({ setShowPopover, isPopoverMounted, switchVisibility, menuRef }) => {
    const [userData, setUserData] = useState(mockUserData);
    useEffect(() => {
        if (localStorage.getItem('userData') && localStorage.getItem('userData') !== 'null'){
            setUserData(JSON.parse(localStorage.getItem('userData'))?.personalData);
        } else {
            setUserData(mockUserData);
        }
    }, [])

    return (
        <Popover setShowPopover={setShowPopover} isPopoverMounted={isPopoverMounted} switchVisibility={switchVisibility} menuRef={menuRef}>
            <S.ProfileBanner>
                    <S.NameIndexDiv>
                    <S.ProfileBannerAvatar img={userData?.photoUrl}></S.ProfileBannerAvatar>
                    <S.ProfileBannerName>{userData?.firstName} {userData?.lastName}</S.ProfileBannerName>
                    <S.ProfileBannerSubtext>Nr albumu {userData?.indexNumber}</S.ProfileBannerSubtext>
                    <S.ProfileBannerSubtext>{userData?.universityName}</S.ProfileBannerSubtext>
                    </S.NameIndexDiv>
                    <LogoutButton/>
            </S.ProfileBanner>
            <DataRow title1={'Kierunek'} value1={userData?.currentMajor} title2={'Stopień'} value2={userData?.currentStageOfStudies} />
            <DataRow title1={'Status studenta'} value1={userData?.studentStatus === 'ACTIVE_STUDENT' ? 'Aktywny' : 'Nieaktywny'} title2={'Rodzaj studiów'} value2={userData?.studiesType}/>
            <DataRow title1={'Wydział'} value1={userData?.currentFaculty} />
        </Popover>
    );
}