import React, {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {apiLink} from "./GlobalVariables";

const AuthContext = createContext(null);

export const RequestLoginAndRedirect = async () => {
  let headers = new Headers();
    headers.append("Content-Type", "application/json");

  let requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "universityId": localStorage.getItem("universityId")
    })
  };

  fetch(`${apiLink}/auth/request-token`, requestOptions)
      .then(response => response.json())
      .then(requestTokenResponse => {
        localStorage.setItem("requestToken", requestTokenResponse.requestToken);
        localStorage.setItem("tokenSecret", requestTokenResponse.tokenSecret);
        window.location.assign(requestTokenResponse.authorizationUrl);
      })
      .catch(error => {
        console.log(error);
      });
};

export const AuthAndRequestData = async (verifier) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  let requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "requestToken": localStorage.getItem("requestToken"),
      "tokenSecret": localStorage.getItem("tokenSecret"),
      "verifier": verifier,
      "universityId": localStorage.getItem("universityId")
    })
  };

  return fetch(`${apiLink}/auth/access-token`, requestOptions)
      .then(response => response.json())
      .then(async accessResponse => {
        return await getData(accessResponse.token);
      })
      .catch(error => {
        console.log(error);
      });
};

export const getData = async (JWT) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${JWT}`);

  let requestOptions = {
    method: 'GET',
    headers: headers,
  };

  return fetch(`${apiLink}/getData`, requestOptions)
      .then(response => { return response.json() })
      .catch(error => {
        console.log(error);
      })
}

export const AuthDemo = async (username, password) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  let requestOptions = {
    method: 'GET',
    headers: headers,
  };

  return fetch(`${apiLink}/getStaticMockedData`, requestOptions)
      .then(response => {
        if(response.status === 403){
          return 403;
        } else {
          return response.json();
        }})
      .catch(error => {
        console.log(error);
      });
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = React.useState(null);

  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  const getUniversities = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };

    return await fetch(`${apiLink}/universities`, requestOptions)
        .then(response => {
          if (response.status === 403) {
            return 403;
          } else {
            return response.json()
          }
        })
        .catch(error => console.log(error));
  }

  const handleLoginDemo = async (event) => {
    const newUserData = await AuthDemo();
    if(newUserData !== undefined){
      setUserData(newUserData);
      localStorage.setItem('userData', JSON.stringify(userData));
      navigate('/dashboard');
    }
    else {
      navigate(`/loginpage`);
    }
  };

  const handleLoginRequest = async () => {
    await RequestLoginAndRedirect();
  };

  const handleLogout = () => {
    console.log('logging out');
    localStorage.clear();
    setUserData(null);
    navigate('/loginpage');
  };

  const handleLoginAccess = async (verifier) => {
    const userData = await AuthAndRequestData(verifier);
    if (userData !== undefined) {
      setUserData(userData);
      localStorage.setItem('userData', JSON.stringify(userData));
      navigate('/dashboard');
    } else {
      navigate('/loginpage');
    }
  }

  const handleUniversityChange = (event) => {
    localStorage.setItem("universityId", event.target.value)
  }

  const value = {
    userData,
    onLoginClick: handleLoginRequest,
    onLoginCallback: handleLoginAccess,
    onLoginDemo: handleLoginDemo,
    onLogout: handleLogout,
    onUniversityChange: handleUniversityChange,
    getUniversities: getUniversities,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  const { userData } = useAuth();
//   const location = useLocation();

  useEffect(() => {
    if(!localStorage.getItem('userData')) navigate('/loginpage');
  }, [userData, navigate]);
  
  /* TO DO: This code needs to be refactored
  if (!token) {
    return <Navigate to="/loginPage" replace state={{ from: location }}/>;
  }
  */

  return children;
};