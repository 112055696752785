import GlobalStyle from './GlobalStyles';
import React, {Fragment, useState, createContext, useEffect} from 'react';
import {LoginPage, LoginPageComponent} from './App/LoginPage/LoginPage.js'
import {Page404, Page404Component} from './App/404Page/404Page.js'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider, ProtectedRoute, } from "./AuthorizationProvider.js";
import {MainPage} from "./App/MainPage/MainPage.js"
import {Mode} from "./App/MainPage/Header/Menu/ColorMode/ColorMode.js";
import Theme from "./Theme";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/react";
import 'bootstrap/dist/css/bootstrap.min.css';

export const ColorModeContext = createContext();

function App() {
  const [colorMode, setColorMode] = useState(Mode.LIGHT);

  const switchColorMode = () => {
      if(colorMode === Mode.LIGHT) {
          setColorMode(Mode.DARK);
          localStorage.setItem('colorMode', 'dark');
      } else {
          setColorMode(Mode.LIGHT);
          localStorage.setItem('colorMode', "light");
      }
  }

    useEffect(() => {
        if (localStorage.getItem('colorMode') === 'dark') {
            setColorMode(Mode.DARK);
        } else if (localStorage.getItem('colorMode') === 'light') {
            setColorMode(Mode.LIGHT);
        }
    }, []);

  initSentry()
  
  return (
    <Fragment>
      <GlobalStyle />
      <Theme colorMode={colorMode}>
        <ColorModeContext.Provider value={{colorMode, switchColorMode}}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="dashboard" element={<ProtectedRoute><MainPage/></ProtectedRoute>}></Route>
                <Route path="loginpage" index element={<LoginPage/>} />
                <Route index element={<LoginPage/>} />
                <Route path="/*" element={<Page404/>}></Route>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </ColorModeContext.Provider>
      </Theme>
    </Fragment>
  );
}

function initSentry() {
    Sentry.init({
        // TODO think about moving the DSN to properties
        dsn: "https://aca5c51a037f4436b40911fd8334d0fc@sentry.team.wmsdev.pl/7",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

export default App;
